/*! modernizr 3.3.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csstransforms-csstransforms3d-flexbox-inlinesvg-svg-svgasimg-setclasses !*/
! function(e, n, t) {
    function r(e, n) {
        return typeof e === n
    }

    function s() {
        var e, n, t, s, i, o, a;
        for (var f in _)
            if (_.hasOwnProperty(f)) {
                if (e = [], n = _[f], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))
                    for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
                for (s = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++) o = e[i], a = o.split("."), 1 === a.length ? Modernizr[a[0]] = s : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = s), w.push((s ? "" : "no-") + a.join("-"))
            }
    }

    function i(e) {
        var n = S.className,
            t = Modernizr._config.classPrefix || "";
        if (x && (n = n.baseVal), Modernizr._config.enableJSClass) {
            var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
            n = n.replace(r, "$1" + t + "js$2")
        }
        Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), x ? S.className.baseVal = n : S.className = n)
    }

    function o(e, n) {
        if ("object" == typeof e)
            for (var t in e) P(e, t) && o(t, e[t]);
        else {
            e = e.toLowerCase();
            var r = e.split("."),
                s = Modernizr[r[0]];
            if (2 == r.length && (s = s[r[1]]), "undefined" != typeof s) return Modernizr;
            n = "function" == typeof n ? n() : n, 1 == r.length ? Modernizr[r[0]] = n : (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = n), i([(n && 0 != n ? "" : "no-") + r.join("-")]), Modernizr._trigger(e, n)
        }
        return Modernizr
    }

    function a() {
        return "function" != typeof n.createElement ? n.createElement(arguments[0]) : x ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments)
    }

    function f() {
        var e = n.body;
        return e || (e = a(x ? "svg" : "body"), e.fake = !0), e
    }

    function l(e, t, r, s) {
        var i, o, l, u, d = "modernizr",
            p = a("div"),
            c = f();
        if (parseInt(r, 10))
            for (; r--;) l = a("div"), l.id = s ? s[r] : d + (r + 1), p.appendChild(l);
        return i = a("style"), i.type = "text/css", i.id = "s" + d, (c.fake ? c : p).appendChild(i), c.appendChild(p), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), p.id = d, c.fake && (c.style.background = "", c.style.overflow = "hidden", u = S.style.overflow, S.style.overflow = "hidden", S.appendChild(c)), o = t(p, e), c.fake ? (c.parentNode.removeChild(c), S.style.overflow = u, S.offsetHeight) : p.parentNode.removeChild(p), !!o
    }

    function u(e, n) {
        return !!~("" + e).indexOf(n)
    }

    function d(e) {
        return e.replace(/([a-z])-([a-z])/g, function(e, n, t) {
            return n + t.toUpperCase()
        }).replace(/^-/, "")
    }

    function p(e, n) {
        return function() {
            return e.apply(n, arguments)
        }
    }

    function c(e, n, t) {
        var s;
        for (var i in e)
            if (e[i] in n) return t === !1 ? e[i] : (s = n[e[i]], r(s, "function") ? p(s, t || n) : s);
        return !1
    }

    function h(e) {
        return e.replace(/([A-Z])/g, function(e, n) {
            return "-" + n.toLowerCase()
        }).replace(/^ms-/, "-ms-")
    }

    function m(n, r) {
        var s = n.length;
        if ("CSS" in e && "supports" in e.CSS) {
            for (; s--;)
                if (e.CSS.supports(h(n[s]), r)) return !0;
            return !1
        }
        if ("CSSSupportsRule" in e) {
            for (var i = []; s--;) i.push("(" + h(n[s]) + ":" + r + ")");
            return i = i.join(" or "), l("@supports (" + i + ") { #modernizr { position: absolute; } }", function(e) {
                return "absolute" == getComputedStyle(e, null).position
            })
        }
        return t
    }

    function g(e, n, s, i) {
        function o() { l && (delete A.style, delete A.modElem) }
        if (i = r(i, "undefined") ? !1 : i, !r(s, "undefined")) {
            var f = m(e, s);
            if (!r(f, "undefined")) return f
        }
        for (var l, p, c, h, g, v = ["modernizr", "tspan", "samp"]; !A.style && v.length;) l = !0, A.modElem = a(v.shift()), A.style = A.modElem.style;
        for (c = e.length, p = 0; c > p; p++)
            if (h = e[p], g = A.style[h], u(h, "-") && (h = d(h)), A.style[h] !== t) {
                if (i || r(s, "undefined")) return o(), "pfx" == n ? h : !0;
                try { A.style[h] = s } catch (y) {}
                if (A.style[h] != g) return o(), "pfx" == n ? h : !0
            }
        return o(), !1
    }

    function v(e, n, t, s, i) {
        var o = e.charAt(0).toUpperCase() + e.slice(1),
            a = (e + " " + N.join(o + " ") + o).split(" ");
        return r(n, "string") || r(n, "undefined") ? g(a, n, s, i) : (a = (e + " " + j.join(o + " ") + o).split(" "), c(a, n, t))
    }

    function y(e, n, r) {
        return v(e, t, t, n, r)
    }
    var w = [],
        _ = [],
        C = {
            _version: "3.3.1",
            _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
            _q: [],
            on: function(e, n) {
                var t = this;
                setTimeout(function() { n(t[e]) }, 0)
            },
            addTest: function(e, n, t) { _.push({ name: e, fn: n, options: t }) },
            addAsyncTest: function(e) { _.push({ name: null, fn: e }) }
        },
        Modernizr = function() {};
    Modernizr.prototype = C, Modernizr = new Modernizr, Modernizr.addTest("svg", !!n.createElementNS && !!n.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect);
    var S = n.documentElement,
        x = "svg" === S.nodeName.toLowerCase(),
        T = "CSS" in e && "supports" in e.CSS,
        b = "supportsCSS" in e;
    Modernizr.addTest("supports", T || b);
    var P;
    ! function() {
        var e = {}.hasOwnProperty;
        P = r(e, "undefined") || r(e.call, "undefined") ? function(e, n) {
            return n in e && r(e.constructor.prototype[n], "undefined")
        } : function(n, t) {
            return e.call(n, t)
        }
    }(), C._l = {}, C.on = function(e, n) { this._l[e] || (this._l[e] = []), this._l[e].push(n), Modernizr.hasOwnProperty(e) && setTimeout(function() { Modernizr._trigger(e, Modernizr[e]) }, 0) }, C._trigger = function(e, n) {
        if (this._l[e]) {
            var t = this._l[e];
            setTimeout(function() {
                var e, r;
                for (e = 0; e < t.length; e++)(r = t[e])(n)
            }, 0), delete this._l[e]
        }
    }, Modernizr._q.push(function() { C.addTest = o }), Modernizr.addTest("svgasimg", n.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1")), Modernizr.addTest("inlinesvg", function() {
        var e = a("div");
        return e.innerHTML = "<svg/>", "http://www.w3.org/2000/svg" == ("undefined" != typeof SVGRect && e.firstChild && e.firstChild.namespaceURI)
    });
    var E = C.testStyles = l,
        z = "Moz O ms Webkit",
        N = C._config.usePrefixes ? z.split(" ") : [];
    C._cssomPrefixes = N;
    var j = C._config.usePrefixes ? z.toLowerCase().split(" ") : [];
    C._domPrefixes = j;
    var k = { elem: a("modernizr") };
    Modernizr._q.push(function() { delete k.elem });
    var A = { style: k.elem.style };
    Modernizr._q.unshift(function() { delete A.style }), C.testAllProps = v, C.testAllProps = y, Modernizr.addTest("flexbox", y("flexBasis", "1px", !0)), Modernizr.addTest("csstransforms", function() {
        return -1 === navigator.userAgent.indexOf("Android 2.") && y("transform", "scale(1)", !0)
    }), Modernizr.addTest("csstransforms3d", function() {
        var e = !!y("perspective", "1px", !0),
            n = Modernizr._config.usePrefixes;
        if (e && (!n || "webkitPerspective" in S.style)) {
            var t, r = "#modernizr{width:0;height:0}";
            Modernizr.supports ? t = "@supports (perspective: 1px)" : (t = "@media (transform-3d)", n && (t += ",(-webkit-transform-3d)")), t += "{#modernizr{width:7px;height:18px;margin:0;padding:0;border:0}}", E(r + t, function(n) { e = 7 === n.offsetWidth && 18 === n.offsetHeight })
        }
        return e
    }), s(), i(w), delete C.addTest, delete C.addAsyncTest;
    for (var L = 0; L < Modernizr._q.length; L++) Modernizr._q[L]();
    e.Modernizr = Modernizr
}(window, document);

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages


                //SVG Fallback

                if (!Modernizr.svg) {
                    var imgs = document.getElementsByTagName('img');
                    var svgExtension = /.*\.svg$/
                    var l = imgs.length;
                    for (var i = 0; i < l; i++) {
                        if (imgs[i].src.match(svgExtension)) {
                            imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
                            console.log(imgs[i].src);
                        }
                    }
                }

                //Menu Toggle Functions

                var $hamburger = $(".hamburger");
                $hamburger.on("click", function(e) {
                    $hamburger.toggleClass("is-active");
                    $("body").toggleClass("openNav");
                    // Do something else, like open/close menu
                });
                
                $(".menu-overlay").click(function() {
                    $("body").removeClass("openNav");
                    $(".hamburger").removeClass("is-active");
                });

                // scroll to 

                 $('a[href^="#"]').on('click', function(event) {
                    var target = $($(this).attr('href'));
                    if (target.length) {
                        event.preventDefault();
                        $('html, body, .page-wrap').animate({
                            scrollTop: target.offset().top
                        }, 500);
                    }
                });


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
               
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'school_district_62_schools': {
            init: function() {
                $(document).ready( function() {
                  var $gallery = $('.team-slider').flickity({
                    prevNextButtons: false,
                    pageDots: false
                  });
                  // Flickity instance
                  var flkty = $gallery.data('flickity');
                  // previous
                  $('.button--previous').on( 'click', function() {
                    $gallery.flickity('previous');
                  });
                  // next
                  $('.button--next').on( 'click', function() {
                    $gallery.flickity('next');
                  });
                });
            }
        },
        'students': {
            init: function() {
                $(document).ready( function() {
                  var $gallery = $('.testimonial-carousel').flickity({
                    prevNextButtons: false,
                    pageDots: false
                  });
                  // Flickity instance
                  var flkty = $gallery.data('flickity');
                  // previous
                  $('.button--previous').on( 'click', function() {
                    $gallery.flickity('previous');
                  });
                  // next
                  $('.button--next').on( 'click', function() {
                    $gallery.flickity('next');
                  });

                 $('.photo-carousel').flickity({
                      cellSelector: '.carousel-cell'
                    });

                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
